import api from "@/utils/api";
import SearchController from "./search_controller"

export default class extends SearchController {
  static targets = [
    ...this.targets,
  ];
  static classes = [
    ...this.classes,
  ]
  static values = {
    ...this.values,
    fontable: String,
  };

  initialize() {
    super.initialize();
  }

  startSearching = (keyword = "") => {
    const trimKeyword = keyword.trim();
    const prevKeyword = this.searchingKeywordValue;
    if (prevKeyword !== trimKeyword) {
      this.pageValue = 1;
    }
    const currentPage = this.hasPageValue ? this.pageValue : 1;
    this.searchingKeywordValue = trimKeyword;
    let query = {page: currentPage }; // , ...this.paramsValue
    if (trimKeyword) {
      query["search"] = trimKeyword;
      query["fontable"] = this.fontableValue
      this.startLoading();
      api.get(`${this.filterApiValue}.json`, query)
        .then((res) => {
          const {fonts, fontables, is_last_page, total_count} = res.data;

          if (total_count === 0) {
            this.renderEmpty();
          } else {
            if (prevKeyword !== trimKeyword) {
              this.resultsTarget.innerHTML = null;
              this.renderPlainTextSelect();
            }
            this.renderResults(fonts, "fonts--search-with-fontables");
            this.renderFontables(fontables);
            this.pageValue = currentPage + 1;
          }
          this.loadingValue = false;
          this.afterLoading();
          this.finishedValue = is_last_page;
        })
    } else {
      this.renderDefault();
      this.afterLoading();
    }
  }

  renderFontables = (fontables) => {
    let templates = [];
    fontables.forEach((fontable) => {
      templates.push(this.fontableTemplate(fontable))
    });
    this.resultsTarget.insertAdjacentHTML('beforeend', templates.join("\n"))
  }

  renderEmpty = () => {
    this.resultsTarget.innerHTML = null;
    this.renderPlainTextSelect();
    const empty = `<li class="relative py-2 px-3 min-h-12 flex items-center justify-center text-gray-500 dark:text-gray-300">${this.notFoundTextValue}</li>`;
    this.resultsTarget.insertAdjacentHTML('beforeend', empty)
  }

  fontableTemplate(fontable) {
    const {
      font_name, fontables_count
    } = fontable;

    const fontDetail = `<div class="flex items-center pointer-events-none"><span class="font-bold">${font_name}</span><span class="font-normal text-gray-600 dark:text-gray-400 ml-3 block truncate">눈누에 없는 폰트</span></div>`;

    return `<li class="flex justify-between items-center select-none relative py-2 px-3 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800"
                data-fontable-font-name="${font_name}"
                data-action="click->fonts--search-with-fontables#onClickFontableResult">${fontDetail}</li>`;
  }

  onClickFontableResult = (e) => {
    const { fontableId, fontableFontName } = e.target.dataset;
    if (this.hasSelectedNameTarget) {
      this.selectedNameTarget.value = fontableFontName;
    }
    this.hideResults();
  }

  renderPlainTextSelect = () => {
    if (this.searchInputTarget.value.trim() !== "") {
      const lastLine = `<li class="select-none relative py-2 px-3 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800"
                data-fontable-font-name="${this.searchInputTarget.value}"
                data-action="click->fonts--search-with-fontables#onClickFontableResult">"<span>${this.searchInputTarget.value}</span>" 그대로 등록하기</li>`;
      this.resultsTarget.insertAdjacentHTML('afterbegin', lastLine)
    }
  }

  preventEnterSubmit = (e) => {
    const keyCode = e.keyCode || e.which;

    if (keyCode === 13) {
      e.preventDefault();
    }
  }

  connect() {
    super.connect();

    this.searchInputTarget.addEventListener("keypress", this.preventEnterSubmit)
  }

  disconnect() {
    super.disconnect();

    this.searchInputTarget.removeEventListener("keypress", this.preventEnterSubmit)
  }
}