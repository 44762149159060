export default {
    messages: {
        ui: {
            "blockTunes": {
                "toggler": {
                    "Click to tune": "클릭 또는 드래그",
                    "or drag to move": "혹은 끌어서 이동"
                },
            },
            "inlineToolbar": {
                "converter": {
                    "Convert to": "변환"
                }
            },
            "toolbar": {
                "toolbox": {
                    "Add": "추가"
                }
            },
            "popover": {
                "Filter": "검색",
                "Nothing found": "결과 없음",
                "Convert to": "변환"
            }
        },
        toolNames: {
            "Text": "텍스트",
            "Heading": "제목",
            "List": "목록",
            "Quote": "인용",
            "Delimiter": "가로줄",
            "Table": "테이블",
            "Marker": "형광펜",
            "Underline": "밑줄",
            "Link": "링크",
            "Bold": "굵게",
            "Italic": "기울림",
            "Image": "이미지",
            "Video": "비디오",
            "InlineCode": "인라인 코드",
            "Raw HTML": "HTML 삽입",
            "Code": "글 박스",
            "ColorPicker": "글자 색",
            "Button": "버튼"
        },

        tools: {
            // "warning": { // <-- 'Warning' tool will accept this dictionary section
            //   "Title": "경고",
            //   "Message": "Сообщение",
            // },
            //
            // /**
            //  * Link is the internal Inline Tool
            //  */
            // "link": {
            //   "Add a link": "링크 추가"
            // },
            // /**
            //  * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
            //  */
            // "stub": {
            //   'The block can not be displayed correctly.': '블럭을 정상적으로 표시할 수 없습니'
            // }
            "image": {
                "Select an Image": "클릭해서 이미지를 올려주세요",
                "Stretch image": "이미지를 넓히기",
                "With border": "테두리 추가",
                "With background": "크기 조절",
                "Add link": "클릭하기 링크 추가",
                "Couldn’t upload image. Please try another.": "올릴 수 없는 이미지 입니다."
            },
            "list": {
                "Unordered": "일반 목록",
                "Ordered": "순서 목록",
            },
            "code": {
                "Enter a code": "내용을 입력하세요. (박스 크기는 컨텐츠에 맞추어서 설정 됩니다)"
            },
            "linkTool": {
                "Couldn't fetch the link data": "해당 링크는 정보를 가져올 수 없습니다. 오타가 없는지 확인해주세요.",
                "Link": "URL을 입력하세요. (https:// 포함)"
            },
            "embed": {
                "Enter a caption": "내용을 입력하세요. (박스 크기는 컨텐츠에 맞추어서 설정 됩니다)"
            },
            "AnyButton": {
                'Button Text': '버튼의 내용을 입력하세요.',
                'Link Url': '버튼의 URL을 입력하세요. (https:// 포함)',
                'Set': "확인",
                'Default Button': "버튼",
                'Edit': "수정",
                'Text Color': "글자 색",
                'Background Color': "배경 색",
            },
            "table": {
                "Add column to left": "왼쪽에 열 추가",
                "Add column to right": "오른쪽에 열 추가",
                "Delete column": "열 삭제하기",
                "Add row above": "윗쪽에 행 추가",
                "Add row below": "아랫쪽에 행 추가",
                "Delete row": "행 삭제하기",
            }
        },
        blockTunes: {
            "delete": {
                "Delete": "삭제",
                "Click to delete": "정말 삭제하기"
            },
            "moveUp": {
                "Move up": "위로 이동"
            },
            "moveDown": {
                "Move down": "아래로 이동"
            }
        },
    }
}