// <div data-controller="fonts--search">
//
// </div>
import { Controller } from "@hotwired/stimulus";;
import api from "@/utils/api";
import {LoadingSpinner, SearchIcon} from "@/utils/helpers";

export default class extends Controller {
  static targets = [
    "container",
    "searchInput",
    "results",
    "selectedId",
    "selectedName",
    "submitButton",
    "cancelButton"
  ];

  static classes = [""]

  static values = {
    filterApi: String,
    page: Number,
    params: Object,
    loading: Boolean,
    finished: Boolean,
    cdnHost: String,
    searchingKeyword: String,
    enterSubmit: Boolean,
    openResult: Boolean,
    defaultText: String,
    notFoundText: String
  };

  initialize() {
    this.searchableTimeout = null;
  }

  focusSearchInput = (e) => {
    this.searchInputTarget.focus();
  }

  onTypeSearchInput = (e) => {
    if (this.searchableTimeout != null) {
      clearTimeout(this.searchableTimeout);
    }

    const trimKeyword = e.target.value.trim();
    if (trimKeyword === "") {
      this.clearSearchSelection();
      return;
    }

    if (trimKeyword === this.searchingKeywordValue) {
      return;
    }

    this.startLoading();
    this.searchableTimeout = setTimeout(() => {
      this.searchableTimeout = null;
      this.startSearching(e.target.value)
    }, 500)
  }

  onCancelInput = (e) => {
    this.searchInputTarget.value = null;
  }

  showResults = (e) => {
    this.resultsTarget.classList.remove("hidden")
  }

  hideResults = (e) => {
    this.resultsTarget.classList.add("hidden")
  }

  startLoading = () => {
    this.loadingValue = true;
    this.submitButtonTarget.innerHTML = LoadingSpinner;
  }

  afterLoading = () => {
    this.loadingValue = false;
    this.submitButtonTarget.innerHTML = SearchIcon;
  }

  startSearching = (keyword = "") => {
    const trimKeyword = keyword.trim();
    const prevKeyword = this.searchingKeywordValue;
    if (prevKeyword !== trimKeyword) {
      this.pageValue = 1;
    }
    const currentPage = this.hasPageValue ? this.pageValue : 1;
    this.searchingKeywordValue = trimKeyword;
    let query = {page: currentPage}; // , ...this.paramsValue
    if (trimKeyword) {
      query["search"] = trimKeyword;
      this.startLoading();
      api.get(`${this.filterApiValue}.json`, query)
        .then((res) => {
          const {fonts, is_last_page, total_count} = res.data;
          if (total_count === 0) {
            this.renderEmpty();
          } else {
            if (prevKeyword !== trimKeyword) {
              this.resultsTarget.innerHTML = null;
            }
            this.renderResults(fonts);
            this.pageValue = currentPage + 1;
          }
          this.afterLoading();
          this.finishedValue = is_last_page;
        })
    } else {
      this.renderDefault();
      this.afterLoading();
    }
  }

  onScroll() {
    if ((this.resultsTarget.scrollTop + this.resultsTarget.clientHeight) >= this.resultsTarget.scrollHeight - 70) {
      if (!this.loadingValue && !this.finishedValue) {
        this.startSearching(this.searchingKeywordValue);
      }
    }
  }

  clearSearchSelection = () => {
    this.pageValue = 1;
    this.loadingValue = false;
    this.searchingKeywordValue = null
    this.afterLoading();
    this.renderDefault();
    if (this.hasSelectedIdTarget) {
      this.selectedIdTarget.value = null;
    }
    if (this.hasSelectedNameTarget) {
      this.selectedNameTarget.value = null;
    }
  }

  renderDefault = () => {
    this.resultsTarget.innerHTML = null;
    const empty = `<li class="relative py-2 px-3 min-h-12 flex items-center justify-center text-gray-500 dark:text-gray-300">${this.defaultTextValue}</li>`;
    this.resultsTarget.insertAdjacentHTML('beforeend', empty)
  }

  renderEmpty = () => {
    this.resultsTarget.innerHTML = null;
    const empty = `<li class="relative py-2 px-3 min-h-12 flex items-center justify-center text-gray-500 dark:text-gray-300">${this.notFoundTextValue}</li>`;
    this.resultsTarget.insertAdjacentHTML('beforeend', empty)
  }

  renderResults = (fonts, controller = "fonts--search") => {
    let templates = [];
    fonts.forEach((font) => {
      templates.push(this.fontTemplate(font, controller))
    });
    this.resultsTarget.insertAdjacentHTML('beforeend', templates.join("\n"))
  }

  fontTemplate(font, controller = "fonts--search") {
    const {
      id, name, style, ph_content,
      loaded_current_user_font,
      company, thumbnail_cdn_key, font_family_token, cdn_server_html
    } = font;

    let fontImage;

    if (thumbnail_cdn_key) {
      fontImage = `<img class="h-6 w-auto object-cover font-image-preview"
                         src="${this.cdnHostValue}/${thumbnail_cdn_key}"
                         alt="${name}">`;
    } else {
      fontImage = `<style>${cdn_server_html}</style><span style="${style};" id="fontEditor${id}">${name}</span>`;
    }

    const fontDetail = `<div class="flex items-center pointer-events-none"><span class="font-bold">${fontImage}</span><span class="font-normal text-gray-600 dark:text-gray-400 ml-3 block truncate">${company.name}</span></div>`;
    // 폰트를 열어 줌
    if (this.openResultValue === true) {
      return `<a href="/font_page/${id}" class="group block select-none relative py-2 px-3 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800">${fontDetail}</a>`;
    } else {
      return `<li class="group select-none relative py-2 px-3 text-gray-900 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800"
                data-font-id="${id}" data-font-name="${name}"
                data-action="click->${controller}#onClickResult">${fontDetail}</li>`;
    }
  }

  onClickResult = (e) => {
    const {fontId, fontName} = e.target.dataset;
    if (this.hasSelectedNameTarget) {
      this.selectedNameTarget.value = fontName;
    }
    if (this.hasSelectedIdTarget) {
      this.selectedIdTarget.value = fontId;
    }
    this.hideResults();
  }

  preventEnterSubmit = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) {
      e.preventDefault();
      return false;
    }
  }

  containerClosable = (e) => {
    if (!this.containerTarget.contains(e.target)) {
      this.resultsTarget.classList.add("hidden")
    }
  }

  connect() {
    if (this.enterSubmitValue === false) {
      this.searchInputTarget.addEventListener("keypress", this.preventEnterSubmit)
    }
    document.addEventListener("click", this.containerClosable)
  }

  disconnect() {
    if (this.searchableTimeout) {
      clearTimeout(this.searchableTimeout);
    }
    if (this.enterSubmitValue === false) {
      this.searchInputTarget.removeEventListener("keypress", this.preventEnterSubmit)
    }
    document.removeEventListener("click", this.containerClosable)
  }
}